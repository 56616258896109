var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"publicationsForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"title","rules":"required","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"title","name":"title","fou":""},on:{"keyup":_vm.generateURL},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"author","rules":"required","name":"The Author"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"author","name":"author","fou":""},model:{value:(_vm.formData.author),callback:function ($$v) {_vm.$set(_vm.formData, "author", $$v)},expression:"formData.author"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"url","rules":"required","name":"The URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"url","name":"url","fou":""},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}})]}}],null,true)}),_c('div',[_c('label',{staticClass:"card-label"},[_vm._v("Date")]),_c('fg-input',{attrs:{"value-format":"YYYY-MM-DD HH:MM:SS","type":"datetime-local","placeholder":"Chose  date"},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Featured")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_featured),callback:function ($$v) {_vm.$set(_vm.formData, "is_featured", $$v)},expression:"formData.is_featured"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"excerpt","rules":"","name":"The Excerpt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"excerpt","name":"excerpt","fou":""},model:{value:(_vm.formData.excerpt),callback:function ($$v) {_vm.$set(_vm.formData, "excerpt", $$v)},expression:"formData.excerpt"}})]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Image - ( width: 1379 , height: 777 )")]),(_vm.getBannerImageInfo())?_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.getBannerImageInfo()))]),_c('span',{staticStyle:{"margin":"0 5px"}},[_c('svg',{staticClass:"bi bi-question-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"}})])])]):_vm._e(),_c('prime-uploader',{attrs:{"preview-width":'200px',"preview-height":'200px',"elementNum":1},model:{value:(_vm.formData.image),callback:function ($$v) {_vm.$set(_vm.formData, "image", $$v)},expression:"formData.image"}})],1)],1),_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Content")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":{
                  height: 400,
                  paste_data_images: true,
                  plugins: [
                  "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                   "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media nonbreaking save table contextmenu directionality",
                  "emoticons template paste textcolor colorpicker textpattern"
                  ],
                  toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                  toolbar2: "print preview media | forecolor backcolor emoticons",
                  image_advtab: true,
                }},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1)]),_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Pdf File")]),(_vm.getBannerPdfInfo())?_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.getBannerPdfInfo()))]),_c('span',{staticStyle:{"margin":"0 5px"}},[_c('svg',{staticClass:"bi bi-question-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"}})])])]):_vm._e(),_c('prime-pdf-uploader',{attrs:{"preview-width":'200px',"preview-height":'200px',"elementNum":1},model:{value:(_vm.formData.file),callback:function ($$v) {_vm.$set(_vm.formData, "file", $$v)},expression:"formData.file"}})],1)])])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/publications/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }